import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import SbciProductMasterApiUrls from '../../../apiUrls/SbciProductMasterApiUrls'
import pathMap from '../../../paths/pathMap'
import { FileForUserGetPartyId } from '../../../common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN, LIKE },
  CodeMaintenanceType
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const { deleteRecord } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const tableRef = useRef(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    product: null
  })
  const theme = useTheme()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PART_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY)
    ]).then(([partType, country]) => setLookups({ partType, country }))
  }, [])

  function makeTranslatedTextsObject() {
    const id = translate(Namespace.SBCI_PRODUCT_MASTER, ProductMasterKeys.ID)
    const productId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PRODUCTID
    )
    const partNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PART_NO
    )
    const partType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PART_TYPE
    )
    const productDesc = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC
    )
    const htsCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.HTS_CODE
    )
    const country = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.COUNTRY
    )

    return {
      id,
      productId,
      partNo,
      partType,
      productDesc,
      htsCode,
      country
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'productId',
      sortKey: 'productId',
      title: translatedTextsObject.productId
    },
    {
      field: 'partNo',
      sortKey: 'partNo',
      title: translatedTextsObject.partNo
    },
    {
      field: 'partType',
      sortKey: 'partType',
      title: translatedTextsObject.partType,
      render: (rowData) => getLookupValue('partType', rowData.partType)
    },
    {
      field: 'productDesc',
      sortKey: 'productDesc',
      title: translatedTextsObject.productDesc
    },
    {
      field: 'htsCode',
      sortKey: 'htsCode',
      title: translatedTextsObject.htsCode
    },
    {
      field: 'country',
      sortKey: 'country',
      title: translatedTextsObject.country,
      render: (rowData) => getLookupValue('country', rowData.country)
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.id,
      type: 'textfield',
      name: 'id',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.productId,
      type: 'textfield',
      name: 'productId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partNo,
      type: 'textfield',
      name: 'partNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partType,
      type: 'select',
      name: 'partType',
      operatorType: IN,
      options: lookups?.partType
        ? Object.entries(lookups.partType).map(([code, label]) => ({
            label: label,
            value: code
          }))
        : []
    },
    {
      label: translatedTextsObject.productDesc,
      type: 'textfield',
      name: 'productDesc',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.htsCode,
      type: 'textfield',
      name: 'htsCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.country,
      type: 'textfield',
      name: 'country',
      operatorType: LIKE
    }
  ]

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleDeleteProduct() {
    if (confirmDialog.product) {
      deleteRecord.execute(
        SbciProductMasterApiUrls.DELETE,
        confirmDialog.product,
        () => {
          showNotification('success', 'Delete succeeded')
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        },
        () => setConfirmDialog({ open: false, product: null })
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.SBCI_PRODUCT_MASTER_ADD_VIEW)
            },
            label: 'Create product'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              history.push(`${pathMap.SBCI_PRODUCT_MASTER}/edit/${rowData.id}`),
            tooltip: () => 'Edit'
          },
          {
            iconButtonProps: {
              style: { color: theme.palette.error.main }
            },
            icon: ['fal', 'trash'],
            onClick: (rowData) =>
              setConfirmDialog({ open: true, product: rowData }),
            tooltip: () => 'Delete'
          }
        ]}
        exportData={{ url: SbciProductMasterApiUrls.EXPORT }}
        fetch={{ url: SbciProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${pathMap.SBCI_PRODUCT_MASTER}/view/${rowData.id}`, {
            cloneData: rowData
          })
        }
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, product: null })}
        onCancel={() => setConfirmDialog({ open: false, product: null })}
        onConfirm={handleDeleteProduct}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
