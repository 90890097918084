import React from 'react'
import { components } from 'cng-web-lib'
import ManifestApiUrls from '../../../../apiUrls/SbciManifestApiUrls'

const {
  form: {
    field: { CngLookupAutocompleteField }
  }
} = components

function SbciManifestTemplateAutoComplete(props) {
  const { lookupProps = {}, ...lookupAutocompleteProps } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngLookupAutocompleteField
      {...lookupAutocompleteProps}
      lookupProps={{
        url: ManifestApiUrls.MANIFEST_BASE_GET,
        label: 'templateName',
        value: 'templateName',
        filters: [
          {
            field: 'status',
            operator: 'equal',
            value: 'TP'
          },
          ...filters
        ],
        ...otherLookupProps
      }}
    />
  )
}

export default SbciManifestTemplateAutoComplete
