import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciManifestKeys from '../../../../constants/locale/key/SbciManifest'
import SbciManifestApiUrls from '../../../../apiUrls/SbciManifestApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import CngSection from '../../../../components/cngcomponents/CngSection'
import moment from 'moment'

const {
  filter: { EQUAL }
} = constants

function ActivityLogs(props) {
  const { manifestId, showNotification } = props

  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const activityLogs = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.ACTIVITY_LOGS
    )
    const functionCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.FUNCTION_CODE
    )
    const auditDescription = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.AUDIT_DESC
    )
    const userId = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.LOGIN_USER_ID
    )
    const createdDate = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.DATE_TIME
    )

    return {
      activityLogs,
      functionCode,
      auditDescription,
      userId,
      createdDate
    }
  }

  const columns = [
    {
      field: 'functionCode',
      title: translatedTextsObject.functionCode
    },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription,
      render: (rowData) => rowData.auditDescription.split('||')[0]
    },
    {
      field: 'userId',
      title: translatedTextsObject.userId
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate &&
        moment(data.createdDate).format('D MMM YYYY - HH:mm')
    }
  ]

  return (
    <CngSection title={translatedTextsObject.activityLogs}>
      <Table
        columns={columns}
        compact
        fetch={{ url: SbciManifestApiUrls.GET_AUDIT_LOGS }}
        fetchFilters={[
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: `SBCI_MANIFEST_${manifestId}`
          }
        ]}
        showNotification={showNotification}
        variant='outlined'
      />
    </CngSection>
  )
}

export default ActivityLogs
