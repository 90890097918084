import React from 'react'
import { components } from 'cng-web-lib'
import InvoiceViewContent from '../invoice/InvoiceViewContent'
import { Box } from '@material-ui/core'

const {
  button: { CngButton },
  CngDialog
} = components

function InvoiceDialog(props) {
  const { open, onClose, data, manifestId, showNotification, title } = props

  const viewContent = (
    <Box padding={2}>
      <InvoiceViewContent
        data={data}
        manifestId={manifestId}
        showNotification={showNotification}
      />
    </Box>
  )

  return (
    <CngDialog
      customDialogContent={data && viewContent}
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle={title}
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default InvoiceDialog
