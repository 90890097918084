import React, { useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import ManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import pathMap from '../../../paths/pathMap'
import FormProperties from './FormProperties'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { Box, Card, Grid } from '@material-ui/core'

const { initialValues } = FormProperties.formikProps

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage(props) {
  const { history, showNotification } = props

  const onSuccessCallback = useRef(() =>
    history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)
  )

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push(`${pathMap.SBCI_MANIFEST}/edit/${e.id}`)
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={{
            ...FormProperties.formikProps,
            initialValues: history.location.state ? FormProperties.toClientDataFormat(history.location.state): initialValues
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: ManifestApiUrls.POST,
            onSuccess: (e) => {
              showNotification('success', 'Manifest created.')
              history.push(`${pathMap.SBCI_MANIFEST}/edit/${e.id}`)
            }
          }}
          renderButtonSection={(loading) => (
            <ManifestAddPageButtonSection
              history={history}
              loading={loading}
              onSaveAndContinue={handleSaveAndContinue}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function ManifestAddPageButtonSection(props) {
  const { history, loading, onSaveAndContinue } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Back to Manifest List
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default AddPage
