import makeValidationSchema from './FccTabMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngCodeMasterAutocompleteField, CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  importConditionNo: '',
  fccIdentifier: '',
  importCondition: '',
  fccRequirement: '',
  tradeName: '',
  withholdInspection: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    const importConditionNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.IMPORT_CONDITION_NO
    )
    const fccIdentifier = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.FCC_IDENTIFIER
    )
    const importCondition = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.IMPORT_CONDITION
    )
    const fccRequirement = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.FCC_REQUIREMENT
    )
    const tradeName = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.TRADE_NAME
    )
    const withholdInspection = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.WITHHOLD_INSPECTION
    )

    return {
      importConditionNo,
      fccIdentifier,
      importCondition,
      fccRequirement,
      tradeName,
      withholdInspection
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.importConditionNo}>
        <CngCodeMasterAutocompleteField
          codeType='SBCI_IMPORT_CONDITION_NO'
          name='importConditionNo'
          label={translatedTextsObject.importConditionNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.fccRequirement}>
        <CngTextField
          name='fccRequirement'
          label={translatedTextsObject.fccRequirement}
          disabled={disabled}
          onChange={(event) => setValue('fccRequirement', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.fccIdentifier}>
        <CngTextField
          name='fccIdentifier'
          label={translatedTextsObject.fccIdentifier}
          disabled={disabled}
          onChange={(event) => setValue('fccIdentifier', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tradeName}>
        <CngTextField
          name='tradeName'
          label={translatedTextsObject.tradeName}
          disabled={disabled}
          onChange={(event) => setValue('tradeName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.withholdInspection}>
        <Card variant='outlined'>
          <Box bgcolor='background.sectionOddBg' px={2} py={1}>
            <Grid alignItems='center' container spacing={2}>
              <Grid item xs>
                <Typography>
                  {translatedTextsObject.withholdInspection}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <CngSelectField
                  name='withholdInspection'
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.importCondition}>
        <Card variant='outlined'>
          <Box bgcolor='background.sectionOddBg' px={2} py={1}>
            <Grid alignItems='center' container spacing={2}>
              <Grid item xs>
                <Typography>
                  {translatedTextsObject.importCondition}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <CngSelectField
                  name='importCondition'
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const FccTabFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FccTabFormProperties
