import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import { ProductMasterLookupsContext } from '../lookups/ProductMasterLookupsContext'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import _ from 'lodash'

function ProductMasterViewContent(props) {
  const { data } = props

  const { lookups, getLookupValue } = useContext(ProductMasterLookupsContext)
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
console.log(lookups)
  const { fdaTab, fccTab } = data

  const hasDeclaration = fdaTab || fccTab

  function makeTranslatedTextsObject() {
    const productMaster = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.TITLE
    )
    const partNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.PART_NO
    )
    const productDesc = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.PRODUCT_DESC
    )
    const partType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.PART_TYPE
    )
    const htsCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.HTS_CODE
    )
    const quantity = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.QUANTITY
    )
    const quantityUom = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.QUANTITY_UOM
    )
    const unitValue = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.UNIT_VALUE
    )
    const uom1Factor1 = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.UOM1_FACTOR1
    )
    const grossWeight = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.GROSS_WT
    )
    const country = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.COUNTRY
    )
    const partRefNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.PART_REF_NO
    )
    const spclPrgInd = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.SPCL_PRG_IND
    )
    const purchaseOrderNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.PURCHASE_ORDER_NO
    )
    const bindRulingIdType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.BIND_RULING_ID_TYPE
    )
    const bindRulingId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.BIND_RULING_ID
    )
    const uom2Factor1 = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.UOM2_FACTOR1
    )
    const netWeight = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.NET_WT
    )
    const ogaInfo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.OgaCode.TITLE
    )
    const ogaCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.OgaCode.OGA_CODE
    )
    const ogaDocNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.OgaCode.OGA_DOC_NO
    )
    const addiInfo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.AddiInfo.TITLE
    )
    const additionalInfoType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.AddiInfo.ADDITIONAL_INFO_TYPE
    )
    const additionalInfoNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.AddiInfo.ADDITIONAL_INFO_NO
    )
    const fdaTab = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.TITLE
    )
    const manufacturerId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.MANUFACTURER_ID
    )
    const storageStatus = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.STORAGE_STATUS
    )
    const marker = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.MARKER
    )
    const fdaTabCountry = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.COUNTRY
    )
    const shipperId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.SHIPPER_ID
    )
    const productCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaTab.PRODUCT_CODE
    )
    const fdaAffirmation = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaAffirmation.TITLE
    )
    const fdaQtyUom = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaQtyUom.TITLE
    )
    const affirmationCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaAffirmation.AFFIRMATION_CODE
    )
    const affirmationQualifier = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaAffirmation.AFFIRMATION_QUALIFIER
    )
    const fdaQtyUomQuantity = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaQtyUom.QUANTITY
    )
    const fdaQtyUomUom = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaQtyUom.UOM
    )
    const fccTab = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.TITLE
    )
    const importConditionNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.IMPORT_CONDITION_NO
    )
    const fccIdentifier = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.FCC_IDENTIFIER
    )
    const importCondition = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.IMPORT_CONDITION
    )
    const fccRequirement = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.FCC_REQUIREMENT
    )
    const tradeName = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.TRADE_NAME
    )
    const withholdInspection = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FccTab.WITHHOLD_INSPECTION
    )

    return {
      productMaster,
      partNo,
      productDesc,
      partType,
      htsCode,
      quantity,
      quantityUom,
      unitValue,
      uom1Factor1,
      grossWeight,
      country,
      partRefNo,
      spclPrgInd,
      purchaseOrderNo,
      bindRulingIdType,
      bindRulingId,
      uom2Factor1,
      netWeight,
      ogaInfo,
      ogaCode,
      ogaDocNo,
      addiInfo,
      additionalInfoType,
      additionalInfoNo,
      fdaTab,
      manufacturerId,
      storageStatus,
      marker,
      fdaTabCountry,
      shipperId,
      productCode,
      fdaAffirmation,
      fdaQtyUom,
      affirmationCode,
      affirmationQualifier,
      fdaQtyUomQuantity,
      fdaQtyUomUom,
      fccTab,
      importConditionNo,
      fccIdentifier,
      importCondition,
      fccRequirement,
      tradeName,
      withholdInspection
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.productMaster}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partNo}>
                    {data.partNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9}>
                  <CngField label={translatedTextsObject.productDesc}>
                    {data.productDesc}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partType}>
                    {data.partType}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.htsCode}>
                    {data.htsCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.quantity}>
                    {data.quantity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.quantityUom}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('uom', data.quantityUom)}
                      <TinyChip label={data.quantityUom} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.unitValue}>
                    {data.unitValue}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.uom1Factor1}>
                    {data.uom1Factor1}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.grossWeight}>
                    {data.grossWt}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.country}>
                    {getLookupValue('country', data.country)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partRefNo}>
                    {data.partRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.spclPrgInd}>
                    {getLookupValue('spclPrgInd', data.spclPrgInd)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.purchaseOrderNo}>
                    {data.purchaseOrderNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.bindRulingIdType}>
                    {getLookupValue('bindRulingIdType', data.bindRulingIdType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.bindRulingId}>
                    {data.bindRulingId}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.uom2Factor1}>
                    {data.uom2Factor1}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.netWeight}>
                    {data.netWt}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            {!_.isEmpty(data.ogaInfo) && (
              <Grid item xs={12}>
                <CngSubSection title={translatedTextsObject.ogaInfo}>
                  <Grid container spacing={1}>
                    {data.ogaInfo.map((code, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.ogaCode}>
                                {getLookupValue('ogaCode', code.ogaCode)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.ogaDocNo}>
                                {code.ogaDocNo}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < data.ogaInfo.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
            {!_.isEmpty(data.addiInfo) && (
              <Grid item xs={12}>
                <CngSubSection title={translatedTextsObject.addiInfo}>
                  <Grid container spacing={1}>
                    {data.addiInfo.map((info, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.additionalInfoType}>
                                {getLookupValue('additionalInfoType', info.additionalInfoType)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.additionalInfoNo}>
                                {info.additionalInfoNo}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < data.addiInfo.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
          </Grid>
        </CngSection>
      </Grid>
      {hasDeclaration && (
        <Grid item xs={12}>
          <CngSection title='Declarations'>
            <Card variant='outlined'>
              <Grid container>
                {fdaTab && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography color='textSecondary' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.fdaTab}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.manufacturerId}>
                                {fdaTab.manufacturerId}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.storageStatus}>
                                {getLookupValue('storageStatus', fdaTab.storageStatus)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.marker}>
                                {getLookupValue('marker', fdaTab.marker)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.fdaTabCountry}>
                                {getLookupValue('country', fdaTab.country)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.shipperId}>
                                {fdaTab.shipperId}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.productCode}>
                                {fdaTab.productCode}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!_.isEmpty(fdaTab.fdaAffirmation) && (
                          <Grid item xs={12}>
                            <Card variant='outlined'>
                              <Box bgcolor='grey.100' px={2} py={1}>
                                <Typography color='textSecondary' style={{ fontWeight: 600 }}>
                                  {translatedTextsObject.fdaAffirmation}
                                </Typography>
                              </Box>
                              <Box p={2}>
                                <Grid container spacing={2}>
                                  {fdaTab.fdaAffirmation.map(
                                    (affirmation, index) => (
                                      <React.Fragment key={index}>
                                        <Grid item xs={12}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                              <CngField label={translatedTextsObject.affirmationCode}>
                                                {getLookupValue('affirmationCode', affirmation.affirmationCode)}
                                              </CngField>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <CngField label={translatedTextsObject.affirmationQualifier}>
                                                {affirmation.affirmationQualifier}
                                              </CngField>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {index + 1 < fdaTab.fdaAffirmation.length && (
                                          <Grid item xs={12}>
                                            <Divider />
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        )}
                        {!_.isEmpty(fdaTab.fdaQtyUom) && (
                          <Grid item xs={12}>
                            <Card variant='outlined'>
                              <Box bgcolor='grey.100' px={2} py={1}>
                                <Typography color='textSecondary' style={{ fontWeight: 600 }}>
                                  {translatedTextsObject.fdaQtyUom}
                                </Typography>
                              </Box>
                              <Box p={2}>
                                <Grid container spacing={2}>
                                  {fdaTab.fdaQtyUom.map((qtyUom, index) => (
                                    <React.Fragment key={index}>
                                      <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6}>
                                            <CngField label={translatedTextsObject.fdaQtyUomQuantity}>
                                              {qtyUom.quantity}
                                            </CngField>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <CngField label={translatedTextsObject.fdaQtyUomUom}>
                                              <Typography component='div' variant='inherit'>
                                                {getLookupValue('uom', qtyUom.uom)}
                                                <TinyChip label={qtyUom.uom} variant='outlined' />
                                              </Typography>
                                            </CngField>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {index + 1 < fdaTab.fdaQtyUom.length && (
                                        <Grid item xs={12}>
                                          <Divider />
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                )}
                {fccTab && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography color='textSecondary' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.fccTab}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.importConditionNo}>
                            {getLookupValue('importConditionNo', fccTab.importConditionNo)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.fccRequirement}>
                            {fccTab.fccRequirement}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.fccIdentifier}>
                            {fccTab.fccIdentifier}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.tradeName}>
                            {fccTab.tradeName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.withholdInspection}>
                            {fccTab.withholdInspection === 'Y' ? 'Yes' : 'No'}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.importCondition}>
                            {fccTab.importCondition === 'Y' ? 'Yes' : 'No'}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </CngSection>
        </Grid>
      )}
    </Grid>
  )
}

export default ProductMasterViewContent
