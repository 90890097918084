import makeValidationSchema from './AddiInfoMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ProductMasterKeys from 'src/constants/locale/key/SbciProductMaster'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  additionalInfoType: '',
  additionalInfoNo: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index, disabled, shouldHideMap }) {
  const { setValue, getValues } = useFormContext()
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const additionalInfoType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.AddiInfo.ADDITIONAL_INFO_TYPE
    )
    const additionalInfoNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.AddiInfo.ADDITIONAL_INFO_NO
    )

    return { additionalInfoType, additionalInfoNo }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.additionalInfoType}>
        <CngCodeMasterAutocompleteField
          codeType='SBCI_ADDITIONAL_INFO_TYPE'
          name={typeof index === 'number' ? `addiInfo.${index}.additionalInfoType` : 'additionalInfoType'}
          label={translatedTextsObject.additionalInfoType}
          disabled={disabled}
          onChange={(_, option) =>
            setValue(typeof index === 'number' ? `addiInfo.${index}.additionalInfoType` : 'additionalInfoType', option ? option.value : '', {shouldValidate:true})
          }
          size='small'
          key={getValues(`addiInfo.${index}.additionalInfoType`)}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.additionalInfoNo}>
        <CngTextField
          name={typeof index === 'number' ? `addiInfo.${index}.additionalInfoNo` : 'additionalInfoNo'}
          label={translatedTextsObject.additionalInfoNo}
          disabled={disabled}
          onChange={(event) =>
            setValue(typeof index === 'number' ? `addiInfo.${index}.additionalInfoNo` : 'additionalInfoNo', event.target.value.toUpperCase(), {shouldValidate:true})
          }
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const AddiInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AddiInfoFormProperties
