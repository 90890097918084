import React, { useRef } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import SbciManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import {
  FileForUserGetPartyId,
  FileForUserGetUserDetails
} from '../../../common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, useTheme } from '@material-ui/core'

const {
  button: { CngButton },
  CngDialog
} = components

const {
  filter: { EQUAL }
} = constants

function AttachInvoiceDialog(props) {
  const {
    open,
    onClose,
    showNotification,
    manifestId,
    manifestStatus,
    onInvoiceClick,
    onAttachInvoice
  } = props

  const tableRef = useRef()
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const attachButton = translate(
      Namespace.SBCI_INVOICE,
      SbciManifestKeys.ATTACH_BUTTON
    )
    const id = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ID)
    const manifestId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.MANIFEST_ID
    )
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    const invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    const entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    const status = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.STATUS)
    const submittedDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMITTED_DATE
    )
    const createdDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CREATED_DATE
    )
    const updatedDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UPDATED_DATE
    )
    const templateName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TEMPLATE_NAME
    )
    const detachButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DETACH_BUTTON
    )
    const attachInvoiceSuccess = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ATTACH_INVOICE_SUCCESS_MSG
    )

    return {
      attachButton,
      id,
      manifestId,
      invoiceNo,
      invoiceDate,
      entryNo,
      status,
      submittedDate,
      createdDate,
      updatedDate,
      templateName,
      detachButton,
      attachInvoiceSuccess
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'invoiceNo',
      sortKey: 'invoiceNo',
      title: translatedTextsObject.invoiceNo
    },
    {
      field: 'invoiceDate',
      sortKey: 'invoiceDate',
      title: translatedTextsObject.invoiceDate,
      render: (data) =>
        data.invoiceDate && moment(data.invoiceDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'manifestId',
      sortKey: 'manifestId',
      title: translatedTextsObject.manifestId
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate && moment(data.createdDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      sortKey: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) =>
        data.submittedDate &&
        moment(data.submittedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'templateName',
      sortKey: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    }
  ]

  function handleAttachSelectedInvoice(rows) {
    if (tableRef.current.setLoading) {
      tableRef.current.setLoading(true)
    }

    securedSendRequest.execute(
      'POST',
      SbciManifestApiUrls.MANIFEST_BASE_ATTACH,
      {
        manifestId: manifestId,
        invoiceId: rows.map((invoice) => invoice.id),
        ...FileForUserGetUserDetails()
      },
      (response) => {
        const { data } = response

        if (data.errorMessages !== null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else if (data.errorMessages === null) {
          showNotification(
            'success',
            translatedTextsObject.attachInvoiceSuccess
          )
          onAttachInvoice()
        }

        onClose()
      },
      (error) => console.error(error),
      () => {
        if (tableRef.current.setLoading) {
          tableRef.current.setLoading(false)
        }
      }
    )
  }

  return (
    <CngDialog
      customDialogContent={
        <Box padding={2}>
          <Table
            checkboxSelection
            columns={columns}
            compact
            customRowActions={[
              {
                iconButtonProps: {
                  icon: ['fal', 'link'],
                  style: { color: theme.palette.primary.main }
                },
                onClick: (rowData) => handleAttachSelectedInvoice([rowData]),
                tooltip: () => translatedTextsObject.attachButton
              }
            ]}
            fetch={{ url: SbciInvoiceApiUrls.ATTACH_SEARCH }}
            fetchFilters={[
              {
                field: 'status',
                operator: EQUAL,
                value: manifestStatus === 'TP' ? 'TP' : 'DR'
              },
              {
                field: 'partyId',
                operator: EQUAL,
                value: FileForUserGetPartyId()
              }
            ]}
            onRowClick={onInvoiceClick}
            selectActions={[
              {
                disabled: (rows) =>
                  rows.some((rowData) => rowData.status === 'ST'),
                icon: <FontAwesomeIcon icon={['fal', 'link']} />,
                label: 'Attach selected',
                onClick: (rows) => handleAttachSelectedInvoice(rows),
                tooltip: (rows) =>
                  rows.some((rowData) => rowData.status === 'ST')
                    ? 'Unable to submit record(s) with "Sent" status'
                    : null
              }
            ]}
            showNotification={showNotification}
            sortConfig={{
              type: 'column',
              defaultDirection: 'DESC',
              defaultField: 'createdDate'
            }}
            tableRef={tableRef}
          />
        </Box>
      }
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle='Attach invoice'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default AttachInvoiceDialog
