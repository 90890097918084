import ProductMasterApiUrls from '../../../apiUrls/SbciProductMasterApiUrls'
import FormProperties from './FormProperties'
import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage(props) {
  const { history, onSetLoading, showNotification } = props

  const { initialValues } = FormProperties.formikProps
  
  return (
    <Card>
      <CardContent>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={{
            ...FormProperties.formikProps,
            initialValues: history.location.state ? FormProperties.toClientDataFormat(history.location.state): initialValues
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: ProductMasterApiUrls.POST,
            successRedirect: pathMap.SBCI_PRODUCT_MASTER_LIST_VIEW
          }}
          renderButtonSection={() => (
            <ProductMasterAddPageButtonSection
              history={history}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </CardContent>
    </Card>
  )
}

function ProductMasterAddPageButtonSection(props) {
  const { history, onSetLoading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={isSubmitting}
            type='submit'
            size='medium'
          >
            Save
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.SBCI_PRODUCT_MASTER_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
