import React, { useContext } from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import { ManifestLookupsContext } from '../lookups/ManifestLookupsContext'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import Table from '../../../components/aciacehighway/Table'
import ManifestResponse from './view/ManifestResponse'
import ActivityLogs from './view/ActivityLogs'
import moment from 'moment'
import {
  Avatar,
  Box,
  Grid,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  transportIcon: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    height: 32,
    width: 32
  }
}))

function ManifestViewContent(props) {
  const { data, manifestId, showNotification } = props

  const { getLookupValue } = useContext(ManifestLookupsContext)
  const classes = useStyles()
  const theme = useTheme()
  const { translate } = useTranslation([
    Namespace.SBCI_MANIFEST,
    Namespace.SBCI_INVOICE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let manifest = translate(Namespace.SBCI_MANIFEST, SbciManifestKeys.TITLE)
    let manifestNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.MANIFEST_NO
    )
    let manifestDate = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.MANIFEST_DATE
    )
    let transportMode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TRANSPORT_MODE
    )
    let clientCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.CLIENT_CODE
    )
    let firmsCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.FIRMS_CODE
    )
    let licensePlateNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.LICENSE_PLATE_NO
    )
    let personInCharge = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PERSON_IN_CHARGE
    )
    let arrivalDate = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.ARRIVAL_DATE
    )
    let portOfArrival = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PORT_OF_ARRIVAL
    )
    let portEntryCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PORT_ENTRY_CODE
    )
    let description = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.DESCRIPTION
    )
    let totalEnteredValue = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_ENTERED_VALUE
    )
    let totalGrossWt = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_GROSS_WT
    )
    let totalNetWt = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_NET_WT
    )
    let totalFreightCharge = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_FREIGHT_CHARGE
    )
    let invoice = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.VIEW_ATTACH_INVOICES
    )
    let invId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ID_IN_MANIFEST
    )
    let invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    let invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    let entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    let grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.GROSS_WEIGHT
    )
    let netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NET_WEIGHT
    )
    let totalValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TOTAL_VALUE
    )

    return {
      manifest,
      manifestNo,
      manifestDate,
      transportMode,
      clientCode,
      firmsCode,
      licensePlateNo,
      personInCharge,
      arrivalDate,
      portEntryCode,
      portOfArrival,
      description,
      totalEnteredValue,
      totalGrossWt,
      totalNetWt,
      totalFreightCharge,
      invoice,
      invId,
      invoiceNo,
      invoiceDate,
      entryNo,
      grossWeight,
      netWeight,
      totalValue
    }
  }

  const columns = [
    {
      title: translatedTextsObject.invId,
      sortKey: 'id',
      field: 'id',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.invoiceNo,
      sortKey: 'invoiceNo',
      field: 'invoiceNo',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.invoiceDate,
      field: 'invoiceDate',
      render: (rowData) =>
        rowData.invoiceDate &&
        moment(data.invoiceDate).format('D MMM YYYY, HH:mm')
    },
    {
      title: translatedTextsObject.entryNo,
      sortKey: 'entryNo',
      field: 'entryNo',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.grossWeight,
      sortKey: 'grossWeight',
      field: 'grossWeight',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.netWeight,
      sortKey: 'netWeight',
      field: 'netWeight',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.totalValue,
      sortKey: 'totalValue',
      field: 'totalValue',
      filterConfig: { type: 'textfield' }
    }
  ]

  function renderTransportModeIcon(mode) {
    let icon

    switch (mode) {
      // Air
      case '40': {
        icon = (
          <FontAwesomeIcon color='inherit' icon={['fal', 'plane']} size='xs' />
        )
        break
      }
      // Truck
      case '30': {
        icon = (
          <FontAwesomeIcon color='inherit' icon={['fal', 'truck']} size='xs' />
        )
        break
      }
      default: {
        icon = null
        break
      }
    }

    return icon && <Avatar className={classes.transportIcon}>{icon}</Avatar>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngSection title={translatedTextsObject.manifest}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.manifestNo}>
                        {data.manifestNo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.manifestDate}>
                        {data.manifestDate &&
                          moment(data.manifestDate).format('D MMM YYYY')}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.transportMode}>
                        <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                          {renderTransportModeIcon(data.transportMode)}
                          <Typography variant='inherit'>
                            {getLookupValue('transportMode', data.transportMode)}
                          </Typography>
                        </Box>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.clientCode}>
                        {data.clientCode}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.firmsCode}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('firmsCode', data.firmsCode)}
                          <TinyChip label={data.firmsCode} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.licensePlateNo}>
                        {data.licensePlateNo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.personInCharge}>
                        {data.personInCharge}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.arrivalDate}>
                        {data.arrivalDate &&
                          moment(data.arrivalDate).format('D MMM YYYY')}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.portEntryCode}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('port', data.portEntryCode)}
                          <TinyChip
                            label={data.portEntryCode}
                            variant='outlined'
                          />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.portOfArrival}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('port', data.portOfArrival)}
                          <TinyChip
                            label={data.portOfArrival}
                            variant='outlined'
                          />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                        <FontAwesomeIcon
                          color={
                            data.hazmatInd === 'Y'
                              ? theme.palette.error.main
                              : theme.palette.success.main
                          }
                          icon={[
                            'fal',
                            data.hazmatInd === 'Y' ? 'circle-check' : 'ban'
                          ]}
                        />
                        <Typography variant='body2'>Hazardous</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.description}>
                        {data.description}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.totalEnteredValue}>
                        {parseFloat(data.totalEnteredValue).toFixed(2)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.totalGrossWt}>
                        {parseFloat(data.totalGrossWt).toFixed(2)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.totalNetWt}>
                        {parseFloat(data.totalNetWt).toFixed(2)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CngField label={translatedTextsObject.totalFreightCharge}>
                        {parseFloat(data.totalFreightCharge).toFixed(2)}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CngSection>
          </Grid>
          <Grid item xs={12}>
            <CngSection title={translatedTextsObject.invoice}>
              <Table
                columns={columns}
                compact
                fetch={{ url: SbciInvoiceApiUrls.SEARCH }}
                fetchFilters={[
                  {
                    field: 'manifestId',
                    operator: EQUAL,
                    value: parseInt(manifestId)
                  }
                ]}
                showNotification={showNotification}
                variant='outlined'
              />
            </CngSection>
          </Grid>
        </Grid>
      </Grid>
      {manifestId && (
        <>
          <Grid item xs={12}>
            <ManifestResponse manifestId={manifestId} />
          </Grid>
          <Grid item xs={12}>
            <ActivityLogs manifestId={manifestId} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ManifestViewContent
