import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'

function makeValidationSchema(translate) {
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_MAX_LENGTH
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]*$'

  return Yup.object({
    id: Yup.string().nullable(),
    prodId: Yup.string().nullable(),
    additionalInfoType: Yup.string().nullable(),
    additionalInfoNo: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, errMsgMaxLength + ' 35')
  })
}

export default makeValidationSchema
