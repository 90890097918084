import makeValidationSchema from './FdaQtyUomMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  quantity: '',
  uom: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index, disabled, shouldHideMap }) {
  const { setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const quantity = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaQtyUom.QUANTITY
    )
    const uom = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      SbciProductMasterKeys.FdaQtyUom.UOM
    )

    return { quantity, uom }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name={typeof index === 'number' ? `fdaQtyUom.${index}.quantity` : 'quantity'}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
          onChange={(event) =>
            setValue(typeof index === 'number' ? `fdaQtyUom.${index}.quantity` : 'quantity', event.target.value,{shouldValidate:true})
          }
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.uom}>
        <CngTextField
          name={typeof index === 'number' ? `fdaQtyUom.${index}.uom` : 'uom'}
          label={translatedTextsObject.uom}
          disabled={disabled}
          onChange={(event) =>
            setValue(typeof index === 'number' ? `fdaQtyUom.${index}.uom` : 'uom', event.target.value.toUpperCase(),{shouldValidate:true})
          }
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const FdaQtyUomFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FdaQtyUomFormProperties
