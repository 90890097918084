import React from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciManifestResponseKeys from '../../../../constants/locale/key/SbciManifestResponse'
import SbciManifestResponseApiUrls from '../../../../apiUrls/SbciManifestResponseApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Link } from '@material-ui/core'

const {
  filter: { EQUAL }
} = constants

function ManifestResponse(props) {
  const { manifestId, showNotification } = props

  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const manifestResponse = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.TITLE
    )
    const entryNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.ENTRY_NO
    )
    const clientCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.CLIENT_CODE
    )
    const receiveDatetime = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.RECEIVE_DATETIME
    )
    const submissionFilePath = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.SUBMISSION_FILE_PATH
    )
    const responseFilePath = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.RESPONSE_FILE_PATH
    )

    return {
      manifestResponse,
      entryNo,
      clientCode,
      receiveDatetime,
      submissionFilePath,
      responseFilePath
    }
  }

  const columns = [
    {
      field: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'clientCode',
      title: translatedTextsObject.clientCode
    },
    {
      field: 'receiveDatetime',
      title: translatedTextsObject.receiveDatetime
    },
    {
      field: 'submissionFilePath',
      title: translatedTextsObject.submissionFilePath,
      render: (rowData) => (
        <Link
          variant='inherit'
          onClick={() => handleDownloadFile(rowData.submissionFilePath)}
        >
          {rowData.submissionFilePath}
        </Link>
      )
    },
    {
      field: 'responseFilePath',
      title: translatedTextsObject.responseFilePath,
      render: (rowData) => (
        <Link
          variant='inherit'
          onClick={() => handleDownloadFile(rowData.responseFilePath)}
        >
          {rowData.responseFilePath}
        </Link>
      )
    }
  ]

  function handleDownloadFile(filePath) {
    securedSendRequest.execute(
      'POST',
      `${SbciManifestResponseApiUrls.DOWNLOAD}?filePath=${filePath}`,
      { responseType: 'blob' },
      (response) => {
        let blob = new Blob([response.data]),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = filePath.replace(/^.*[\\\/]/, ''),
          disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      },
      () => showNotification('error', 'File not found.')
    )
  }

  return (
    <CngSection title={translatedTextsObject.manifestResponse}>
      <Table
        columns={columns}
        compact
        exportData={{ url: SbciManifestResponseApiUrls.EXPORT }}
        fetch={{ url: SbciManifestResponseApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: parseInt(manifestId) }
        ]}
        showNotification={showNotification}
        variant='outlined'
      />
    </CngSection>
  )
}

export default ManifestResponse
