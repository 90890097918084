import { Card, CardContent, Grid, Paper } from '@material-ui/core'
import React,{useState} from 'react'; 
//import CardHeader from "components/Card/CardHeader.js"; 
import RegularButton from "src/components/CustomButton.js";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import sweetalert from 'sweetalert'
import { 
  components,
  useServices
} from 'cng-web-lib';
import SbciInvoiceApiUrls from 'src/apiUrls/SbciInvoiceApiUrls.js'
import SbciProductMasterApiUrls from 'src/apiUrls/SbciProductMasterApiUrls.js'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'

var cardStyle = {
  //display: 'block',
  //width: '81vw',
  //transitionDuration: '0.3s',
 height: '41vw'
}
const{
  card: {
     CngSimpleCardHeader,
  },
   CngGridItem,
}=components

function UploadResponse() { 
  
  
  const {securedSendRequest } = useServices();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, seFileData] = useState(null);
  const [dropdownvalue, setDropdownvalue] = useState('select');

  const { translate } = useTranslation([
    Namespace.SBCI_INVOICE
  ])

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let csvMaxFileSize = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MAX_FILE_SIZE
    )

    let csvInvalidFileType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_INVALID_FILE_TYPE
    )

    let csvFileType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_TYPE
    )

    let csvInvalidFile = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_INVALID_FILE
    )

    let csvMessageType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE
    )

    let csvSelectMessageType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_SELECT_MESSAGE_TYPE
    )

    let csvMessageTypeInv = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_INV
    )

    let csvMessageTypePm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_PM
    )

    let csvFileUploadSuccess = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_UPLOAD_SUCCESS
    )

    let csvFileUploadError = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_UPLOAD_ERROR
    )

    let uploadButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UPLOAD_BUTTON
    )

    let csvFileDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_DETAILS
    )

    let csvFileName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_NAME
    )

    let csvFileLastModified = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_LAST_MODIFIED
    )

    let csvSelectFile = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_SELECT_FILE
    )

    let csvTitle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_TITLE
    )

    return{
      csvMaxFileSize,
      csvInvalidFileType,
      csvFileType,
      csvInvalidFile,
      csvMessageType,
      csvSelectMessageType,
      csvMessageTypeInv,
      csvMessageTypePm,
      csvFileUploadSuccess,
      csvFileUploadError,
      uploadButton,
      csvFileDetails,
      csvFileName,
      csvFileLastModified,
      csvSelectFile,
      csvTitle
    }

  }
  

  const handleChange = (event) => {
    console.log(event.target.value)
    setDropdownvalue(event.target.value);

  };

  // On file select (from the pop up) 
  const onFileChange = event => { 
    console.log(event.target.files[0])

    const file = event.target.files[0];
    // Update the state 
    setSelectedFile(file)
    
  }; 
   
  // On file upload (click the upload button) 
  const onFileUpload = () => { 

      let fileForUserDetails = FileForUserGetUserDetails();

      let file = selectedFile;
      if(file!=null){
        // if(file.type != ".csv"){
        //   sweetalert("File type must be in csv format","" ,"error");
        //   return false;
        // }
        if(file.size > 6291456){
          sweetalert(translatedTextsObject.csvMaxFileSize,"" ,"error");
          return false;
        }

        if(file.type!="text/csv"){
          sweetalert(translatedTextsObject.csvInvalidFileType,"" ,"error");
          return false;
        }

      }else{
        sweetalert(translatedTextsObject.csvInvalidFile,"" ,"error");
        return false;
      }

      const formData = new FormData(); 

      if(fileForUserDetails!=null && fileForUserDetails!=undefined){
        formData.append("fileForUserDetails",JSON.stringify(fileForUserDetails))
      }else{
        formData.append("fileForUserDetails","{}")
      }     

      formData.append('file', selectedFile);
          

    // Request made to the backend api 
    // Send formData object 
  
    var url='';
    if(dropdownvalue != null &&dropdownvalue != 'select') 
    {
      if(dropdownvalue=='INV'){
          url=SbciInvoiceApiUrls.UPLOAD;
      }
      else if(dropdownvalue=='PM'){
         url=SbciProductMasterApiUrls.UPLOAD;
      }

      function onSuccess(response) {
        sweetalert(translatedTextsObject.csvFileUploadSuccess,"" ,"success");
      }
    
      function onError(error) {
        //showNotification("error", errorMsg)
        sweetalert(translatedTextsObject.csvFileUploadError,"","error")
      }
    
      function onComplete(){
    
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    
      securedSendRequest.execute('POST',url, formData, onSuccess, onError, onComplete,config,null);
    }
    else{
      sweetalert(translatedTextsObject.csvSelectMessageType)
    } 
    
  }
   
  // File content to be displayed after 
  // file upload is complete 
  const displayFileData = () => { 
   
    if (selectedFile) { 
        
      return ( 
        <div> 
          <br />
          <h3>{translatedTextsObject.csvFileDetails}:</h3> 
          <br />
          <p>{translatedTextsObject.csvFileName}:{selectedFile.name}</p> 
          <br />
          <p>{translatedTextsObject.csvFileType}:{selectedFile.type}</p> 
          <br />
          <p> 
          {translatedTextsObject.csvFileLastModified}:{" "} 
            {selectedFile.lastModifiedDate.toDateString()} 
          </p> 
        </div> 
      ); 
    } else { 
      return ( 
        <div> 
          <br /> 
          <br />
          <h4>{translatedTextsObject.csvSelectFile}</h4> 
        </div> 

      ); 
    } 
  }; 
   
    return ( 
      <Card style={cardStyle}>
        <CngSimpleCardHeader title={translatedTextsObject.csvTitle}/>
        <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={12}>
              <CngGridItem xs={12} sm={6}>
                 <InputLabel id="messagetype">{translatedTextsObject.csvMessageType}</InputLabel>
                 <Select
                      labelId="message type"
                      id="rtype"
                      value={dropdownvalue}
                      onChange={handleChange}
                      style={{width:'50%'}}
                  >
                 <MenuItem value="INV">{translatedTextsObject.csvMessageTypeInv}</MenuItem>
                 <MenuItem value="PM">{translatedTextsObject.csvMessageTypePm}</MenuItem>
                </Select>
             </CngGridItem>
             <div>
             <span></span>
             <br></br>
             </div>
             <div>
             <span></span>
             <br></br>
             </div>
             <Grid item xs={12}>
                <Grid container>
                  <CngGridItem xs={12} sm={4}>
                  <RegularButton>
                    <input type="file"accept=".csv" color="primary" onChange={onFileChange}/>
                  </RegularButton>
                  </CngGridItem>
                  <CngGridItem xs={6} sm={3}>
                  <RegularButton  type="submit" color="primary" onClick={onFileUpload}
                    disabled={!selectedFile} >
                    {translatedTextsObject.uploadButton}
                  </RegularButton>
                  </CngGridItem>
                </Grid>
              </Grid>
                {displayFileData()} 
        </Grid>
        </Grid>  
        </CardContent>
        </Card>
    ); 
} 

export default UploadResponse; 