import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]*$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    id: Yup.string().nullable(),
    prodId: Yup.string().nullable(),
    ogaCode: Yup.string().nullable().required(requiredMessage),
    ogaDocNo: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, 'Max length allowed is 35.')
      .nullable()
      .required(requiredMessage)
  })
}

export default makeValidationSchema
