import React, { useRef, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ManifestKeys from '../../../constants/locale/key/SbciManifest'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import pathMap from '../../../paths/pathMap'
import ManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import {
  FileForUserGetPartyId,
  FileForUserGetUserDetails
} from '../../../common/FileForUserCommon'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'
import { Chip, CircularProgress, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const { translate } = useTranslation([
    Namespace.SBCI_MANIFEST,
    Namespace.SBCI_INVOICE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const tableRef = useRef(null)
  const theme = useTheme()
  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitDialog, setSubmitDialog] = useState({
    open: false,
    data: null
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    manifest: null
  })

  function makeTranslatedTextsObject() {
    const id = translate(Namespace.SBCI_MANIFEST, ManifestKeys.ID)
    const manifestNo = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.MANIFEST_NO
    )
    const arrivalDate = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.ARRIVAL_DATE
    )
    const manifestDate = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.MANIFEST_DATE
    )
    const status = translate(Namespace.SBCI_MANIFEST, ManifestKeys.STATUS)
    const submittedDate = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.SUBMITTED_DATE
    )
    const cloneButton = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.CLONE_BUTTON
    )
    const submitButton = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.SUBMIT_BUTTON
    )
    const createdDate = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.CREATED_DATE
    )
    const editSentRecords = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.EDIT_SENT_RECORDS
    )
    const editButton = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.EDIT_BUTTON
    )
    const deleteSentRecords = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.DELETE_SENT_RECORDS
    )
    const deleteButton = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.DELETE_BUTTON
    )
    const templateName = translate(
      Namespace.SBCI_MANIFEST,
      ManifestKeys.TEMPLATE_NAME
    )
    const cancelButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CANCEL_BUTTON
    )
    const sendToUPS = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SEND_TO_UPS
    )
    const sendManifestWithInvoices = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SEND_MANIFEST_WITH_INVOICES
    )
    const submitSuccessMsg = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    const errorMessage = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ERROR_MESSAGE
    )
    const submitSentRecord = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMIT_SENT_RECORD
    )

    return {
      id,
      manifestNo,
      arrivalDate,
      manifestDate,
      status,
      submittedDate,
      cloneButton,
      submitButton,
      createdDate,
      editSentRecords,
      editButton,
      deleteSentRecords,
      deleteButton,
      templateName,
      cancelButton,
      sendToUPS,
      sendManifestWithInvoices,
      submitSuccessMsg,
      errorMessage,
      submitSentRecord
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'manifestNo',
      sortKey: 'manifestNo',
      title: translatedTextsObject.manifestNo
    },
    {
      field: 'manifestDate',
      sortKey: 'manifestDate',
      title: translatedTextsObject.manifestDate,
      render: (rowData) =>
        rowData.manifestDate &&
        moment(rowData.manifestDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'arrivalDate',
      sortKey: 'arrivalDate',
      title: translatedTextsObject.arrivalDate,
      render: (rowData) =>
        rowData.arrivalDate &&
        moment(rowData.arrivalDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'templateName',
      sortKey: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => {
        const status = getStatusMetadata(rowData.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.id,
      type: 'textfield',
      name: 'id',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.manifestNo,
      type: 'textfield',
      name: 'manifestNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.manifestDate,
      type: 'daterange',
      name: 'manifestDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.arrivalDate,
      type: 'daterange',
      name: 'arrivalDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.templateName,
      type: 'textfield',
      name: 'templateName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: 'DR'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: 'AC'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: 'ST'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: 'RJ'
          }
        },
        {
          fullWidth: true,
          label: 'Incomplete',
          value: 'incomplete',
          filterValue: {
            value: 'IN'
          }
        },
        {
          fullWidth: true,
          label: 'Template',
          value: 'template',
          filterValue: {
            value: 'TP'
          }
        }
      ]
    }
  ]

  function handleCloneManifest(rowData) {
    createRecord.execute(
      ManifestApiUrls.MANIFEST_BASE_CLONE,
      rowData,
      (data) => {
        data.manifestDate = moment(data.manifestDate).format('YYYY-MM-DD')
        data.arrivalDate = moment(data.arrivalDate).format('YYYY-MM-DD')
        history.push(pathMap.SBCI_MANIFEST_ADD_VIEW, data)
      },
      (error) => console.log(error)
    )
  }

  function handleDeleteManifest() {
    if (confirmDialog.manifest) {
      deleteRecord.execute(
        ManifestApiUrls.DELETE,
        confirmDialog.manifest,
        () => {
          showNotification('success', 'Delete succeeded')
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        },
        () => setConfirmDialog({ open: false, manifest: null })
      )
    }
  }

  function handleSubmitManifest() {
    let manifest = [...submitDialog.data]
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      manifest.forEach((invoice) => {
        invoice['fileForUserId'] = fileForUserDetails.fileForUserId
        invoice['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
        invoice['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
      })
    }

    setIsSubmitting(true)

    if (tableRef.current.setLoading) {
      tableRef.current.setLoading(true)
    }

    securedSendRequest.execute(
      'POST',
      ManifestApiUrls.SUBMIT_ALL,
      manifest,
      (response) => {
        if (response.status == 200) {
          const data = response.data
          var errorMessage = null

          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessage = obj.errorMessages
              showNotification('error', errorMessage)
            }
          }

          if (errorMessage == null || errorMessage.length == 0) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)

            if (tableRef.current.performRefresh) {
              tableRef.current.performRefresh()
            }
          }
        } else {
          showNotification('error', translatedTextsObject.errorMessage)
        }
      },
      () => showNotification('error', translatedTextsObject.errorMessage),
      () => {
        setIsSubmitting(false)
        setSubmitDialog({ open: false, data: null })

        if (tableRef.current.setLoading) {
          tableRef.current.setLoading(false)
        }
      }
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.SBCI_MANIFEST_ADD_VIEW)
            },
            label: 'Create manifest'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              history.push(`${pathMap.SBCI_MANIFEST}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecords
                : translatedTextsObject.editButton
          }
        ]}
        exportData={{ url: ManifestApiUrls.EXPORT }}
        fetch={{ url: ManifestApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${pathMap.SBCI_MANIFEST}/view/${rowData.id}`, {
            cloneData: rowData
          })
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneManifest
          },
          {
            disabled: (rowData) => rowData.status !== 'DR' && rowData.status !== 'TP',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, manifest: rowData }),
            tooltip: (rowData) =>
              rowData.status !== 'DR' && rowData.status !== 'TP'
                ? translatedTextsObject.deleteSentRecords
                : null
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                color: theme.palette.primary.main,
                minWidth: 'auto'
              }
            },
            disabled: (rows) =>
              isSubmitting ||
              rows.some(
                (manifest) =>
                  manifest.status === 'ST' || manifest.status === 'TP'
              ),
            icon: isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
            ),
            label: translatedTextsObject.submitButton,
            onClick: (rows) => setSubmitDialog({ open: true, data: rows }),
            tooltip: (rows) =>
              rows.some(
                (manifest) =>
                  manifest.status === 'ST' || manifest.status === 'TP'
              )
                ? translatedTextsObject.submitSentRecord
                : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel={translatedTextsObject.cancelButton}
        confirmLabel={translatedTextsObject.submitButton}
        loading={isSubmitting}
        open={submitDialog.open}
        onClose={() => setSubmitDialog({ open: false, data: null })}
        onCancel={() => setSubmitDialog({ open: false, data: null })}
        onConfirm={handleSubmitManifest}
        title={translatedTextsObject.sendToUPS}
      >
        {translatedTextsObject.sendManifestWithInvoices}
      </AlertDialog>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, manifest: null })}
        onCancel={() => setConfirmDialog({ open: false, manifest: null })}
        onConfirm={handleDeleteManifest}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
