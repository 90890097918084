import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgMinLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_MAX_LENGTH
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_DECIMAL3
  )
  const errMsgNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciManifestKeys.Validations.ERROR_MSG_NUMERIC
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const regexAlphaNumericNoSpecialChar = '^[a-zA-Z0-9 ]*$'

  return Yup.object({
    manifestNo: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(15, errMsgMaxLength + ' 15')
      .required(requiredMessage),
    clientCode: Yup.string().nullable(),
    filerCode: Yup.string(),
    firmsCode: Yup.string()
      .nullable()
      .max(4, errMsgMaxLength + '  4'),
    arrivalDate: Yup.lazy((value) =>
      value === '' || value === null || value === undefined
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    manifestDate: Yup.lazy((value) =>
      value === '' || value === null || value === undefined
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    portEntryCode: Yup.string().nullable().required(requiredMessage),
    portOfArrival: Yup.string().nullable().required(requiredMessage),
    description: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .required(requiredMessage)
      .max(60, errMsgMaxLength + ' 60.'),
    transportMode: Yup.string().nullable().required(requiredMessage),
    licensePlateNo: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(10, errMsgMaxLength + ' 10.'),
    personInCharge: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(23, errMsgMaxLength + ' 23.'),
    totalEnteredValue: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(
              /^\d{0,8}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(11, errMsgMaxLength + ' 11')
    ),
    totalGrossWt: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(
              /^\d{0,8}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(11, errMsgMaxLength + ' 11')
    ),
    totalNetWt: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(
              /^\d{0,8}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(11, errMsgMaxLength + ' 11')
    ),
    totalFreightCharge: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(/^\d{0,8}(\.\d{0,2})?$/, errMsgNumeric)
            .max(8, errMsgMaxLength + ' 8')
    ),
    hazmatInd: Yup.string().required(requiredMessage),
    status: Yup.string().nullable(),
    corpid: Yup.string(),
    templateName: Yup.string()
      .nullable()
      .when('status', (status) => {
        if (status == 'TP') {
          return Yup.string()
            .max(50, errMsgMaxLength + ' 50')
            .required(requiredMessage)
            .matches(
              regexAlphaNumericNoSpecialChar,
              errMsgAlphaNumericNoSpecialChar
            )
        } else {
          return Yup.string()
            .when('templateFlag', {
              is: true,
              then: Yup.string()
                .max(1, errMsgMinLength + ' 1')
                .max(50, errMsgMaxLength + ' 50')
                .required(requiredMessage)
                .matches(
                  regexAlphaNumericNoSpecialChar,
                  errMsgAlphaNumericNoSpecialChar
                )
            })
            .max(50, errMsgMaxLength + ' 50')
            .matches(
              regexAlphaNumericNoSpecialChar,
              errMsgAlphaNumericNoSpecialChar
            )
            .nullable()
        }
      })
  })
}

export default makeValidationSchema
