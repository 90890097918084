import React, { useEffect } from 'react'
import Namespace from '../../../constants/locale/Namespace'
import ProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { components, DateTimeFormatter, constants, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import CngSection from '../../../components/cngcomponents/CngSection'
import _ from 'lodash'
import OgaCodeSection from './OgaInfoSection'
import AddiInfoSection from './AddiInfoSection'
import FdaTabSection from './FdaTabSection'
import FccTabSection from './FccTabSection'
import OgaCodeFormProperties from './OgaCodeFormProperties'
import AddiInfoFormProperties from './AddiInfoFormProperties'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngUomAutocompleteField,
      CngCountryAutocompleteField
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  productId: "",
  partNo: "",
  htsCode: "",
  productDesc: "",
  partType: "",
  purchaseOrderNo: "",
  country: "",
  unitValue: "",
  bindRulingIdType: "",
  bindRulingId: "",
  spclPrgInd: "",
  naftaCriteria: "",
  dateRangeFrom: "",
  dateRangeTo: "",
  netCost: "",
  isProducer: "",
  notAProducerOfGoods: "",
  includeProdNafta: "",
  partRefNo: "",
  quantity: "",
  quantityUom: "",
  uom1Factor1: "",
  uom2Factor1: "",
  grossWt: "",
  netWt: "",
  corpid: "",
  submittedBy: "",
  submittedDate: "",
  partyId:"",
  addiInfo: [],
  ogaInfo: [],
  fccTab: null,
  fdaTab: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const partType = watch('partType')
  const quantityUom = watch('quantityUom')
  const country = watch('country')
  const spclPrgInd = watch('spclPrgInd')
  const bindRulingIdType = watch('bindRulingIdType')
  const ogaInfo = watch('ogaInfo')
  const addiInfo = watch('addiInfo')
  const fdaTab = watch('fdaTab')
  const fccTab = watch('fccTab')

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    const productMaster = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.TITLE
    )
    const productId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PRODUCTID
    )
    const partNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PART_NO
    )
    const htsCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.HTS_CODE
    )
    const productDesc = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC
    )
    const partType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PART_TYPE
    )
    const purchaseOrderNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PURCHASE_ORDER_NO
    )
    const partRefNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.PART_REF_NO
    )
    const bindRulingIdType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.BIND_RULING_ID_TYPE
    )
    const bindRulingId = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.BIND_RULING_ID
    )
    const spclPrgInd = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.SPCL_PRG_IND
    )
    const unitValue = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.UNIT_VALUE
    )
    const country = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.COUNTRY
    )
    const quantity = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.QUANTITY
    )
    const quantityUom = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.QUANTITY_UOM
    )
    const uom1Factor1 = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.UOM1_FACTOR1
    )
    const uom2Factor1 = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.UOM2_FACTOR1
    )
    const grossWt = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.GROSS_WT
    )
    const netWt = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.NET_WT
    )
    const ogaInfo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.OgaCode.TITLE
    )
    const ogaCode = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.OgaCode.OGA_CODE
    )
    const ogaDocNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.OgaCode.OGA_DOC_NO
    )
    const addiInfo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.AddiInfo.TITLE
    )
    const additionalInfoType = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.AddiInfo.ADDITIONAL_INFO_TYPE
    )
    const additionalInfoNo = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.AddiInfo.ADDITIONAL_INFO_NO
    )
    const fdaTab = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.FdaTab.TITLE
    )
    const fccTab = translate(
      Namespace.SBCI_PRODUCT_MASTER,
      ProductMasterKeys.FccTab.TITLE
    )

    return {
      productMaster,
      productId,
      partNo,
      htsCode,
      productDesc,
      partType,
      purchaseOrderNo,
      partRefNo,
      bindRulingIdType,
      bindRulingId,
      spclPrgInd,
      unitValue,
      country,
      quantity,
      quantityUom,
      uom1Factor1,
      uom2Factor1,
      grossWt,
      netWt,
      ogaInfo,
      ogaCode,
      ogaDocNo,
      addiInfo,
      additionalInfoType,
      additionalInfoNo,
      fdaTab,
      fccTab
    }
  }

	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.productMaster}
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>*</Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.productId}>
                  <CngTextField
                    name='productId'
                    label={translatedTextsObject.productId}
                    disabled={disabled}
                    onChange={(event) => setValue('productId', event.target.value.toUpperCase(), {shouldValidate:true})}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partNo}>
                  <CngTextField
                    name='partNo'
                    label={translatedTextsObject.partNo}
                    disabled={disabled}
                    onChange={(event) => setValue('partNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={6} shouldHide={shouldHideMap?.productDesc}>
                  <CngTextField
                    name='productDesc'
                    label={translatedTextsObject.productDesc}
                    disabled={disabled}
                    onChange={(event) => setValue('productDesc', event.target.value.toUpperCase(), {shouldValidate:true})}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partType}>
                  <CngCodeMasterAutocompleteField
                    codeType='SBCI_PART_TYPE'
                    key={partType}
                    name='partType'
                    label={translatedTextsObject.partType}
                    disabled={disabled}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.htsCode}>
                  <CngTextField
                    name='htsCode'
                    label={translatedTextsObject.htsCode}
                    disabled={disabled}
                    onChange={(event) => setValue('htsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.quantity}>
                  <CngTextField
                    name='quantity'
                    label={translatedTextsObject.quantity}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.quantityUom}>
                  <CngUomAutocompleteField
                    key={quantityUom}
                    name='quantityUom'
                    label={translatedTextsObject.quantityUom}
                    disabled={disabled}
                    size='small'
                    lookupProps={{
                      label: (record) => {
                        return `${record.code};${record.descriptionEn}`
                      },
                      filters: [
                        { field: 'uomType', operator: EQUAL, value: 'usb' }
                      ]
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.unitValue}>
                  <CngTextField
                    name='unitValue'
                    label={translatedTextsObject.unitValue}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.uom1Factor1}>
                  <CngTextField
                    name='uom1Factor1'
                    label={translatedTextsObject.uom1Factor1}
                    disabled={disabled}
                    onChange={(event) => setValue('uom1Factor1', event.target.value.toUpperCase(), {shouldValidate:true})}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.grossWt}>
                  <CngTextField
                    name='grossWt'
                    label={translatedTextsObject.grossWt}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.country}>
                  <CngCountryAutocompleteField
                    key={country}
                    name='country'
                    label={translatedTextsObject.country}
                    disabled={disabled}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partRefNo}>
                  <CngTextField
                    name='partRefNo'
                    label={translatedTextsObject.partRefNo}
                    disabled={disabled}
                    onChange={(event) => setValue('partRefNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.spclPrgInd}>
                  <CngCodeMasterAutocompleteField
                    codeType='SBCI_SPECIAL_PROGRAM_IND'
                    key={spclPrgInd}
                    name='spclPrgInd'
                    label={translatedTextsObject.spclPrgInd}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.purchaseOrderNo}>
                  <CngTextField
                    name='purchaseOrderNo'
                    label={translatedTextsObject.purchaseOrderNo}
                    disabled={disabled}
                    onChange={(event) => setValue('purchaseOrderNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.bindRulingIdType}>
                  <CngCodeMasterAutocompleteField
                    codeType='SBCI_BIND_RULING_ID_TYPE'
                    key={bindRulingIdType}
                    name='bindRulingIdType'
                    label={translatedTextsObject.bindRulingIdType}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.bindRulingId}>
                  <CngTextField
                    name='bindRulingId'
                    label={translatedTextsObject.bindRulingId}
                    disabled={disabled}
                    onChange={(event) => setValue('bindRulingId', event.target.value.toUpperCase(), {shouldValidate:true})}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.uom2Factor1}>
                  <CngTextField
                    name='uom2Factor1'
                    label={translatedTextsObject.uom2Factor1}
                    disabled={disabled}
                    onChange={(event) => setValue('uom2Factor1', event.target.value.toUpperCase(), {shouldValidate:true})}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.netWt}>
                  <CngTextField
                    name='netWt'
                    label={translatedTextsObject.netWt}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!_.isEmpty(ogaInfo)}
                onExpandedChange={(expanded) => {
                  const { initialValues } = OgaCodeFormProperties.formikProps
                  setValue('ogaInfo', expanded ? [{ ...initialValues, _id: 1 }] : [])
                }}
                title={translatedTextsObject.ogaInfo}
              >
                <OgaCodeSection ogaInfo={ogaInfo} />
              </CollapsibleSection>
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!_.isEmpty(addiInfo)}
                onExpandedChange={(expanded) => {
                  const { initialValues } = AddiInfoFormProperties.formikProps
                  setValue('addiInfo', expanded ? [{ ...initialValues, _id: 1 }] : [])
                }}
                title={translatedTextsObject.addiInfo}
              >
                <AddiInfoSection addiInfo={addiInfo} />
              </CollapsibleSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Declarations'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FdaTabSection
                fdaTab={fdaTab}
                onSave={(data) => setValue('fdaTab', data, { shouldValidate: true })}
                title={translatedTextsObject.fdaTab}
              />
            </Grid>
            <Grid item xs={12}>
              <FccTabSection
                fccTab={fccTab}
                onSave={(data) => setValue('fccTab', data, { shouldValidate: true })}
                title={translatedTextsObject.fccTab}
              />
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  console.log(localData)
  localData.dateRangeFrom = DateTimeFormatter.toClientDate(
    localData.dateRangeFrom
  );
  localData.dateRangeTo = DateTimeFormatter.toClientDate(
    localData.dateRangeTo
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.dateRangeFrom = DateTimeFormatter.toServerDate(
    localData.dateRangeFrom
  );
  localData.dateRangeTo = DateTimeFormatter.toServerDate(
    localData.dateRangeTo
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
