import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      importConditionNo: Yup.string().nullable(),
      fccIdentifier: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(17, errMsgMaxLength + ' 17'),
      importCondition: Yup.string().nullable(),
      fccRequirement: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(3, errMsgMaxLength + ' 3'),
      tradeName: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(30, errMsgMaxLength + ' 30'),
      withholdInspection: Yup.string().nullable()
    })
}

export default makeValidationSchema
