import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_MAX_LENGTH
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL3
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]*$'

  return Yup.object({
    quantity: Yup.string()
      .nullable()
      .matches(
        /^\d{0,8}(\.\d{0,2})?$/,
        errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
      )
      .max(11, errMsgMaxLength + ' 11')
      .required(requiredMessage),
    uom: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(4, errMsgMaxLength + ' 4.')
      .nullable()
      .required(requiredMessage)
  })
}

export default makeValidationSchema
