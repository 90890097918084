import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './AddiInfoFormProperties'
import AddiInfoFieldsPlaceholder from './AddiInfoFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import AddiInfoFormProperties from './AddiInfoFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function AddiInfoSection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const addiInfo = watch('addiInfo')

  function handleAddAddiInfo(data) {
    const addiInfo = [...getValues('addiInfo'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('addiInfo', addiInfo, { shouldDirty: true })
  }

  function handleDeleteAddiInfo(data) {
    const clonedAddiInfo = [...getValues('addiInfo')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const addiInfo = clonedAddiInfo.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('addiInfo', addiInfo, { shouldDirty: true })
  }

  return (
    <Grid container spacing={1}>
      {addiInfo.map((goods, index) => {
        const isLast = addiInfo.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <AddiInfoEntry
                index={index}
                isLast={isLast}
                onAddAddiInfo={handleAddAddiInfo}
                onDeleteAddiInfo={handleDeleteAddiInfo}
                addiInfo={goods}
                showNotification={showNotification}
              />
            </Grid>
            {addiInfo.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function AddiInfoEntry(props) {
  const {  index, isLast, onAddAddiInfo, onDeleteAddiInfo, addiInfo, showNotification} = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <AddiInfoFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddAddiInfo(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddAddiInfo({ ...addiInfo, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteAddiInfo(addiInfo)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddiInfoSection
