import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import SbciManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import FormProperties from './FormProperties'
import { Grid, Box, Card } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const { history, onSetLoading, showNotification } = props

  const { id } = useParams()
  const { securedSendRequest } = useServices()

  function handleSubmitManifest(data) {
    try {
      onSetLoading(true)

      const fileForUserDetails = FileForUserGetUserDetails()

      if (fileForUserDetails) {
        const { fileForUserId, fileForUserLoginId, fileForUserPartyId } =
          fileForUserDetails

        data['fileForUserId'] = fileForUserId
        data['fileForUserLoginId'] = fileForUserLoginId
        data['fileForUserPartyId'] = fileForUserPartyId
      }

      securedSendRequest.execute(
        'POST',
        SbciManifestApiUrls.SUBMIT_ALL,
        [data],
        (response) => {
          const data = response.data
          var errorMessage = null

          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessage = obj.errorMessages
              showNotification('error', errorMessage)
            }
          }

          if (errorMessage == null || errorMessage.length == 0) {
            showNotification('success', 'Manifest submitted successfully.')
            history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)
          }
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Manifest submit failed.')
        },
        () => onSetLoading(false)
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{ url: SbciManifestApiUrls.GET }}
          update={{ 
            url: SbciManifestApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                e.fileForUserId = fileForUserDetails.fileForUserId;
                e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ManifestEditPageButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
              onSubmitManifest={handleSubmitManifest}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function ManifestEditPageButtonSection(props) {
  const { history, loading, onSetLoading, onSubmitManifest } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Back to Manifest List
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                size='medium'
                onClick={() => onSubmitManifest(getValues())}
              >
                Submit
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
