import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciProductMasterKeys from '../../../constants/locale/key/SbciProductMaster'
import OgaCodeMakeValidationSchema from '../productmaster/OgaCodeMakeValidationSchema'
import AddiInfoMakeValidationSchema from '../productmaster/AddiInfoMakeValidationSchema'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_MAX_LENGTH
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_PRODUCT_MASTER,
    SbciProductMasterKeys.Validations.ERROR_MSG_DECIMAL3
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]*$'

  return Yup.object({
    productId: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(50, errMsgMaxLength + ' 50')
      .required(requiredMessage),
    partNo: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(25, errMsgMaxLength + ' 25')
      .required(requiredMessage),
    htsCode: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(10, errMsgMaxLength + ' 10')
      .required(requiredMessage),
    productDesc: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .required(requiredMessage)
      .max(50, errMsgMaxLength + ' 50'),
    partType: Yup.string().nullable().required(requiredMessage),
    purchaseOrderNo: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(20, errMsgMaxLength + ' 20')
      .nullable(),
    country: Yup.string().nullable().required(requiredMessage),
    unitValue: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,6}(\.\d{0,5})?$/,
              errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
            )
            .max(12, errMsgMaxLength + ' 12')
    ),
    bindRulingIdType: Yup.string().nullable(),
    bindRulingId: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(20, errMsgMaxLength + ' 20')
      .nullable(),
    spclPrgInd: Yup.string().nullable(),
    naftaCriteria: Yup.string().nullable(),
    partRefNo: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(20, errMsgMaxLength + ' 20')
      .nullable(),
    quantity: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,7}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 7 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(10, errMsgMaxLength + ' 10')
    ),
    quantityUom: Yup.string().nullable(),
    uom1Factor1: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,6}(\.\d{0,5})?$/,
              errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
            )
            .max(12, errMsgMaxLength + ' 12')
    ),
    uom2Factor1: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,6}(\.\d{0,5})?$/,
              errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
            )
            .max(12, errMsgMaxLength + ' 12')
    ),
    grossWt: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,7}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 7 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(10, errMsgMaxLength + ' 10')
    ),
    netWt: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,7}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 7 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(10, errMsgMaxLength + ' 10')
    ),
    addiInfo: Yup.array(
      AddiInfoMakeValidationSchema(translate)
    ).nullable(),
    ogaInfo: Yup.array(
      OgaCodeMakeValidationSchema(translate)
    ).nullable(),
    fccTab: Yup.object().nullable(),
    fdaTab: Yup.object().nullable()
  })
}

export default makeValidationSchema
