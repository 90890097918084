import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import FormProperties from './FdaQtyUomFormProperties'
import FdaQtyUomFieldsPlaceholder from './FdaQtyUomFieldsPlaceholder'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import FdaQtyUomFormProperties from './FdaQtyUomFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function FdaQtyUomSection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const fdaQtyUom = watch('fdaQtyUom')

  function handleAddFdaQtyUom(data) {
    const fdaQtyUom = [...getValues('fdaQtyUom'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('fdaQtyUom', fdaQtyUom, { shouldDirty: true })
  }

  function handleDeleteFdaQtyUom(data) {
    const clonedFdaQtyUom = [...getValues('fdaQtyUom')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const fdaQtyUom = clonedFdaQtyUom.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('fdaQtyUom', fdaQtyUom, { shouldDirty: true })
  }

  return (
    <Grid container spacing={1}>
      {fdaQtyUom.map((goods, index) => {
        const isLast = fdaQtyUom.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <FdaQtyUomEntry
                index={index}
                isLast={isLast}
                onAddFdaQtyUom={handleAddFdaQtyUom}
                onDeleteFdaQtyUom={handleDeleteFdaQtyUom}
                fdaQtyUom={goods}
                showNotification={showNotification}
              />
            </Grid>
            {fdaQtyUom.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function FdaQtyUomEntry(props) {
  const { index, isLast , onAddFdaQtyUom, onDeleteFdaQtyUom, fdaQtyUom, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <FdaQtyUomFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddFdaQtyUom(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddFdaQtyUom({ ...fdaQtyUom, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteFdaQtyUom(fdaQtyUom)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FdaQtyUomSection
