import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices, useTranslation } from 'cng-web-lib'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'
import Namespace from '../../../constants/locale/Namespace'
import pathMap from '../../../paths/pathMap'
import SbciManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import ManifestLookupsProvider from '../lookups/ManifestLookupsContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import ManifestViewContent from './ManifestViewContent'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props

  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { createRecord, deleteRecord, fetchRecord } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecord.execute(SbciManifestApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  function makeTranslatedTextsObject() {
    const editButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      SbciManifestKeys.EDIT_BUTTON
    )
    const cloneButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      SbciManifestKeys.CLONE_BUTTON
    )
    const deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      SbciManifestKeys.DELETE_BUTTON
    )

    return { editButton, cloneButton, deleteButton }
  }

  if (!data) {
    return <CircularProgress />
  }

  function handleCloneManifest() {
    if (history.location.state?.cloneData) {
      createRecord.execute(
        SbciManifestApiUrls.MANIFEST_BASE_CLONE,
        history.location.state.cloneData,
        (data) => {
          history.push(pathMap.SBCI_MANIFEST_ADD_VIEW, data)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleDeleteManifest() {
    deleteRecord.execute(
      SbciManifestApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <ManifestLookupsProvider>
            <ManifestViewContent
              data={data}
              manifestId={id}
              showNotification={showNotification}
            />
          </ManifestLookupsProvider>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.SBCI_MANIFEST_LIST_VIEW)}
          >
            Back to Manifest List
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {(data.status === 'DR' || data.status === 'TP') && (
              <CngButton
                onClick={() => setConfirmDialog(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleCloneManifest}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== 'ST' && (
              <CngButton
                color='primary'
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.SBCI_MANIFEST}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteManifest}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
