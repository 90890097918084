import React, { useEffect } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import FormProperties from './FdaTabFormProperties'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Grid } from '@material-ui/core'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function FdaTabSection(props) {
  const { fdaTab, onSave, title } = props
  const { translate } = useTranslation(Namespace.SBCI_PRODUCT_MASTER)
  const methods = useForm({
    defaultValues: fdaTab || initialValues,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty, isSubmitting },
    handleSubmit
  } = methods

  useEffect(() => {
    methods.reset(fdaTab)
  }, [fdaTab])

  async function onSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSave(data)
        methods.reset(data)
        resolve()
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <CollapsibleSection defaultExpanded={!!fdaTab} title={title}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProperties.Fields />
          </Grid>
          {isDirty && (
            <Grid item xs={12}>
              <Grid container justify='flex-end' spacing={1}>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    disabled={isSubmitting}
                    onClick={() => methods.reset(fdaTab || initialValues)}
                    size='small'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    disabled={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    shouldShowProgress={isSubmitting}
                    size='small'
                  >
                    Save
                  </CngButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CollapsibleSection>
    </FormProvider>
  )
}

export default FdaTabSection
