import  SbciProductMasterApiUrls  from '../../../../apiUrls/SbciProductMasterApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngServerSideAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function SbciProductMasterAutoCompleteMasterField(props) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={2}
      debounceInterval={1000}
      openOnFocus={false}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['productId']}
      lookupPageableProps={{
        customData: { "filters":filters},
        url: SbciProductMasterApiUrls.GET,
        label: 'productId',
        value: 'productId',
        filters: [
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

export default SbciProductMasterAutoCompleteMasterField
